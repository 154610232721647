import React from 'react';
import Layout from '../ui/layout/Layout';
import LoginForm from '../ui/domains/authentication/LoginForm';

const Anmelden = () => {
    return (
        <Layout>
            <LoginForm theme="blue" />
        </Layout>
    );
};

export default Anmelden;
